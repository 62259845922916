import React, { useState, useEffect } from "react";
import { Button, Card, Alert } from "react-bootstrap";
import ReactGA from "react-ga4";

function registerAdClick() {
  ReactGA.event({
    category: "navigation",
    action: "click_ad",
    label: "ad_1", // optional
    // value: 99, // optional, must be a number
    // nonInteraction: false, // optional, true/false
    // transport: "xhr", // optional, beacon/xhr/image
  });
}

function DuolingoNinjaAd() {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("G-V2T4J6Y9YR");
      window.GA_INITIALIZED = true;
    }
  }, []);

  if (show) {
    return (
      <center>
        {/* <Alert
          variant="light"
          className="mt-4 mb-4"
          style={{ border: "1px gray solid", maxWidth: "700px" }}
          onClose={() => setShow(false)}
          dismissible
        >
          <Card className="border-0">
            <Card.Body className="mx-1">
              <Card.Title
                style={{ fontWeight: "normal", fontSize: "2.4em" }}
                className="my-4"
              >
                Learn your{" "}
                <span style={{ color: "#58cc02", fontWeight: "bold" }}>
                  duolingo
                </span>{" "}
                vocabulary while you browse the web
              </Card.Title>
              <Card.Text>
                <a href="/">Duolingo Ninja</a> translates words and sentences on
                web pages you visit automatically based on your Duolingo
                vocabulary.
              </Card.Text>
              <Button
                variant="success"
                className="my-2"
                href="/"
                onClick={() => registerAdClick()}
              >
                Check out the free version
              </Button>
            </Card.Body>
          </Card>
        </Alert> */}
        <Alert
          variant="light"
          className="mt-4 mb-4"
          style={{ border: "1px gray solid", maxWidth: "700px" }}
          onClose={() => setShow(false)}
          dismissible
        >
          <Card className="border-0">
            {/* <Card.Img variant="top" src={logo192} /> */}
            <Card.Body className="mx-1">
              <Card.Title
                style={{ fontWeight: "normal", fontSize: "2.4em" }}
                className="my-4"
              >
                Making no progress with{" "}
                <span style={{ color: "#58cc02", fontWeight: "bold" }}>
                  duolingo
                </span>
                ?
              </Card.Title>
              <Card.Text>
                Try{" "}
                <a href="https://lingochampion.com?utm_source=duolingo_ninja&utm_medium=duolingo_ninja_website&utm_campaign=duolingo_ninja_vocabulary_page_no_progress_ad">
                  Lingo Champion
                </a>{" "}
                to pick up languages by reading native speaker content.
              </Card.Text>
              <Button
                variant="success"
                className="my-2"
                href="https://lingochampion.com?utm_source=duolingo_ninja&utm_medium=duolingo_ninja_website&utm_campaign=duolingo_ninja_vocabulary_page_no_progress_ad"
                // onClick={() => registerAdClick()}
              >
                Check out the free version
              </Button>
            </Card.Body>
          </Card>
        </Alert>
      </center>
    );
  }
  return (
    <Button onClick={() => setShow(true)} variant="light">
      Show ad
    </Button>
  );
}

export default DuolingoNinjaAd;

import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import AppMetaDataContext from "../context/AppMetaDataContext";

function Footer(props) {
  const metaData = React.useContext(AppMetaDataContext);
  return (
    <>
      <div className="text-center p-2 mb-3" style={{ marginTop: "5%" }}>
        <a
          href="https://tally.so/r/3xYYEE"
          target="_blank"
          style={{ color: "black" }}
        >
          Feedback & bugs
        </a>{" "}
        |{" "}
        <LinkContainer to="/release-notes">
          <a href="/release-notes" style={{ color: "black" }}>
            What's new?
          </a>
        </LinkContainer>{" "}
        |{" "}
        <LinkContainer to="/privacy-policy">
          <a href="/privacy" style={{ color: "black" }}>
            Privacy policy
          </a>
        </LinkContainer>{" "}
        |{" "}
        <LinkContainer to="/terms-of-service">
          <a href="/terms-of-service" style={{ color: "black" }}>
            Terms of Service
          </a>
        </LinkContainer>{" "}
        |{" "}
        <LinkContainer to="/duolingo-flashcards">
          <a href="/duolingo-flashcards" style={{ color: "black" }}>
            Duolingo flashcards
          </a>
        </LinkContainer>{" "}
        <br />
        <br />
        <span>
          {" "}
          © {new Date().getFullYear()} {metaData.appName} | Duolingo Ninja is
          not affiliated with Duolingo
        </span>
      </div>
    </>
  );
}

export default Footer;
